import styled from 'styled-components';
import { MenuButton, MenuItemOption, MenuList } from '@chakra-ui/react';

export const StyledMenuButton = styled(MenuButton)`
  position: sticky;
  top: ${({ theme }) => `calc(${theme.spacing} + 20px)`};
  padding-right: ${({ theme }) => theme.spacing};

  display: flex !important;
  margin-left: auto;
  margin-bottom: 20px;

  z-index: 10;

  span {
    display: flex;
    flex-direction: row;
    align-items: center;

    background: ${({ theme }) => theme.colors.secondBackground};
    height: 40px;
    padding: 0 10px;
    border-radius: ${({ theme }) => theme.borderRadius.sm};
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
    background: ${({ theme }) => theme.colors.primary};

    transition: opacity 0.3s;
  }

  @media (max-width: ${({ theme }) => theme.mobileWidth}) {
    top: ${({ theme }) => theme.mobileSpacing};
    padding-right: ${({ theme }) => theme.mobileSpacing};

    span {
      margin-right: 0;
    }
  }

  &:hover {
    opacity: 0.9;
  }

  p {
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.3px;
    color: ${({ theme }) => theme.colors.onPrimary};

    margin-right: 3px;

    &:last-child {
      margin-right: 0;
    }
  }

  svg {
    font-size: 15px;
    color: ${({ theme }) => theme.colors.onPrimary};
  }
`;

export const StyledMenuList = styled(MenuList)`
  display: flex;
  flex-direction: column;

  box-shadow: 1px 5px 20px rgba(0, 0, 0, 0.2) !important;
  margin-right: ${({ theme }) => `calc(${theme.spacing} / 2)`};

  padding: 0 !important;

  strong {
    background: ${({ theme }) => theme.colors.secondBackground};

    font-size: 13px;
    color: ${({ theme }) => theme.colors.onBackground};
    font-weight: 600;

    padding: 10px 15px;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.onBackground}`};
  }

  @media (max-width: ${({ theme }) => theme.mobileWidth}) {
    margin-right: ${({ theme }) => `calc(${theme.mobileSpacing} / 2)`};
  }
`;

export const StyledMenuItemOption = styled(MenuItemOption)`
  padding: 0 !important;
  margin: 0 !important;

  span:first-child {
    display: none;
  }

  > span {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    background: ${({ theme }) => theme.colors.secondBackground};
    padding: 20px 15px;
    border-bottom: ${({ theme }) => `1px solid ${theme.colors.onBackground}`};

    &:last-child {
      border-bottom: none;
    }

    transition: opacity 0.3s;

    &:hover {
      opacity: 0.8;
    }

    > div {
      &:first-child {
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          color: ${({ theme }) => theme.colors.onBackground};
          font-size: 19px;
        }

        span {
          color: ${({ theme }) => theme.colors.onBackground};
          font-size: 14px;

          margin-left: 15px;
        }
      }

      &:last-child {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 20px;
        height: 20px;
        border: ${({ theme }) => `2px solid ${theme.colors.onBackground}`};
        border-radius: ${({ theme }) => theme.borderRadius.xlg};

        margin-left: 20px;

        svg {
          color: ${({ theme }) => theme.colors.secondBackground};
          font-size: 12px;
        }
      }

      &.selected {
        background: ${({ theme }) => theme.colors.onBackground};
      }
    }
  }
`;
