import styled from 'styled-components';

export const LabelText = styled.span`
  display: inline-block;

  background: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.onPrimary};

  padding: 0.55rem 2.5rem;
  border-radius: ${({ theme }) => theme.borderRadius.sm};

  line-height: 13px;

  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.6px;
`;
