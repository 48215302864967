import { FC } from 'react';

import { useTheme } from 'styled-components';

import { useMediaQuery } from '~/hooks/mediaQuery';

import { LabelText } from '~/components/LabelText';

import { List, ListContainer } from '../../styles';

import { ICategory } from '~/interfaces/ICategory';

import { Category } from './Category';

interface IProps {
  label?: string;
  categories: ICategory[];
}

export const CategoriesList: FC<IProps> = ({
  label = 'Categorias',
  categories,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.mobileWidth})`);

  return (
    <ListContainer>
      <LabelText
        style={{
          marginLeft: isMobile ? theme.mobileSpacing : theme.spacing,
        }}
      >
        {label}
      </LabelText>

      <List>
        {categories &&
          categories.map((category) => (
            <Category key={`category-${category._id}`} category={category} />
          ))}
      </List>
    </ListContainer>
  );
};
