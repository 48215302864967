import { FC } from 'react';

import Link from 'next/link';

import { routes } from '~/constants/routes';

import { useStore } from '~/hooks/store';

import { ImageContainer } from '~/components/ImageContainer';

import { Container } from './styles';

import { ICategory } from '~/interfaces/ICategory';

interface IProps {
  category: ICategory;
}

export const Category: FC<IProps> = ({ category }) => {
  const { store } = useStore();

  return (
    <Link href={routes.category(store?.tag, category._id)}>
      <Container>
        <ImageContainer pictures={[category.picture]} />

        <h1>{category.name}</h1>
      </Container>
    </Link>
  );
};
