import { FC } from 'react';

import { FiShoppingCart } from 'react-icons/fi';
import { BiHomeAlt } from 'react-icons/bi';

import { useToast } from '@chakra-ui/react';

import { useStore } from '~/hooks/store';
import { useDrawer } from '~/hooks/drawer';
import { useCart } from '~/hooks/cart';

import { Container } from './styles';

import { ProfileItem } from '../ProfileItem';
import { CartDrawer } from '../Drawers/CartDrawer';

export const NavBottomBar: FC = () => {
  const toast = useToast({
    isClosable: true,
    position: 'top-right',
    status: 'warning',
  });
  const { openDrawer } = useDrawer();
  const { cartTotals } = useCart();
  const { isOpen } = useStore();

  function openCart(): void {
    if (!isOpen) {
      toast({
        title: 'Ops,',
        description: 'A loja está fechada!',
      });

      return;
    }

    openDrawer('Resumo do pedido', <CartDrawer />);
  }

  return (
    <Container>
      <div className="bottom-nav-buttons maxWidth">
        <button type="button">
          <BiHomeAlt />
          <div className="line" />
        </button>

        <button type="button" onClick={() => openCart()}>
          {cartTotals.quantity > 0 && (
            <span className="cart-quantity">{cartTotals.quantity}</span>
          )}
          <FiShoppingCart />
        </button>

        <ProfileItem atNavBottom />
      </div>
    </Container>
  );
};
