import { minPriceCombo } from '~/utils/minPriceCombo';

import { IProduct } from '~/interfaces/IProduct';
import { ICategory } from '~/interfaces/ICategory';

import { api } from './api';

interface IParams {
  showProducts?: boolean;
  showSubcategories?: boolean;
  storeId?: string;
}

export async function loadCategories(params?: IParams): Promise<ICategory[]> {
  const response = await api.get('/categories', {
    params: {
      ...params,
    },
  });

  const categoriesMapping = (response.data || []).map((cat: ICategory) => ({
    ...cat,
    products: (cat?.products || []).reduce(
      (newList: IProduct[], nextProd: IProduct) => {
        if (nextProd?.comboType !== 'carvery') {
          if (
            nextProd.price === 0 &&
            nextProd.comboJourneys &&
            nextProd.comboJourneys.length > 0
          ) {
            newList.push({
              ...nextProd,
              price: minPriceCombo(nextProd),
              isComboWithinValue: true,
            });
          } else newList.push(nextProd);
          return newList;
        }
        return newList;
      },
      []
    ),
  }));

  return categoriesMapping;
}

export async function loadCategory(
  id: string,
  params: IParams
): Promise<ICategory> {
  const response = await api.get(`/categories/${id}`, {
    params: {
      ...params,
    },
  });

  const productsFiltereds = (response?.data?.products || []).filter(
    (product: IProduct) => product?.comboType !== 'carvery'
  );

  const categoryMapping = {
    ...response.data,
    products: productsFiltereds.map((prod: IProduct) => {
      if (
        prod.price === 0 &&
        prod.comboJourneys &&
        prod.comboJourneys.length > 0
      ) {
        return {
          ...prod,
          price: minPriceCombo(prod),
          isComboWithinValue: true,
        };
      }
      return prod;
    }),
  };

  return categoryMapping;
}
