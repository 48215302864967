import styled, { keyframes } from 'styled-components';

const spin = keyframes`
  from { transform: rotate(0deg) }
  to { transform: rotate(360deg) }
`;

export const Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  z-index: 9999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;

  width: 100%;
  height: 100%;
  padding: 7% 0;

  background: ${({ theme }) => theme.colors.background};

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-bottom: 50%;

      .loader {
        height: 45px;
        width: 45px;

        border-radius: 50%;
        border-width: 5px;
        border-style: solid;
        border-color: ${({ theme }) => theme.colors.background};
        border-top-color: ${({ theme }) => theme.colors.onBackground};
        border-right-color: ${({ theme }) => theme.colors.onBackground};

        animation: ${spin} 0.6s linear infinite;
      }

      p {
        font-size: 18px;
        font-weight: 600;
        color: ${({ theme }) => theme.colors.onBackground};

        margin-top: 15%;
      }
    }
  }
`;
