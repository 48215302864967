/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Fragment, useEffect, useState, useMemo } from 'react';

import { useRouter } from 'next/router';
import { GetStaticPaths, InferGetStaticPropsType, NextPage } from 'next';

import { useToast } from '@chakra-ui/react';

import { useStore } from '~/hooks/store';
import { useSortProducts } from '~/hooks/sortProducts';
import { useMediaQuery } from '~/hooks/mediaQuery';
import { useLayouts } from '~/hooks/layouts';

import SortButton from '~/components/SortButton';
import { Products } from '~/components/Products/ProductsList';
import { PageLoading } from '~/components/PageLoading';
import { NavBottomBar } from '~/components/NavBottomBar';
import { CategoriesList } from '~/components/Categories/CategoriesList';

import { withSSRStore } from '~/utils/withSSRStore';

import { checkStoreIsOpen } from '~/services/store';
import {
  loadBestSellingProducts,
  loadPromotionalProducts,
  loadProducts,
} from '~/services/products';
import { loadCategories } from '~/services/categories';
import { env } from '~/constants/env';

export const getStaticPaths: GetStaticPaths = async () => {
  return {
    fallback: true,
    paths: [],
  };
};

export const getStaticProps = withSSRStore(async (_, store) => {
  const { data: promotionalProductsData } = await loadPromotionalProducts();

  const { data: bestSellingProductsData } = await loadBestSellingProducts();

  const { data: withoutCategoryProductsData } = await loadProducts({
    showWithoutCategories: true,
  });

  const isOpen = await checkStoreIsOpen();

  return {
    props: {
      promotionalProductsData: promotionalProductsData || [],
      bestSellingProductsData: bestSellingProductsData || [],
      withoutCategoryProductsData: withoutCategoryProductsData || [],
      store: store || null,
      isOpen: isOpen || null,
    },
    revalidate: Number(env.REVALIDATE_TIME),
  };
});

const HomePage: NextPage<InferGetStaticPropsType<typeof getStaticProps>> = ({
  promotionalProductsData,
  bestSellingProductsData,
  withoutCategoryProductsData,
  isOpen,
}) => {
  const [loading, setLoading] = useState<boolean>(true);
  const isSmallerThan550px = useMediaQuery('(max-width: 550px)');
  const { isFallback } = useRouter();
  const { store, setIsOpen } = useStore();
  const { secondLayoutId } = useLayouts();
  const {
    bestSellingProducts,
    setBestSellingProducts,
    categoriesWithSubcategories,
    setCategoriesWithSubcategories,
    withoutCategoryProducts,
    setWithoutCategoryProducts,
    promotionalProducts,
    setPromotionalProducts,
  } = useSortProducts();

  const toast = useToast({
    title: 'Ops, ocorreu um erro',
    status: 'error',
    isClosable: true,
    position: 'top-right',
  });

  useEffect(() => {
    (async () => {
      if (store?._id) {
        try {
          const categoriesWithSubcategoriesData = await loadCategories({
            showProducts: true,
            showSubcategories: true,
            storeId: store._id,
          });

          setCategoriesWithSubcategories(categoriesWithSubcategoriesData);
        } catch {
          toast({
            status: 'error',
            title: 'Ops',
            description:
              'Houve um erro ao carregar as categorias e subcategorias, recarregue a página e tente novamente!',
          });
        } finally {
          setLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  const isSmallerThan650px = useMediaQuery('(max-width: 650px)');

  const showPromotionalProducts =
    store.showProductsWithPromotion &&
    promotionalProducts &&
    promotionalProducts.length > 0;

  const showBestSellingProducts =
    store?.showBestSellerProducts &&
    bestSellingProducts &&
    bestSellingProducts.length > 0;

  const showCategories =
    categoriesWithSubcategories &&
    categoriesWithSubcategories.length > 0 &&
    store.layout !== secondLayoutId;

  const showCategoryProducts =
    (!(
      promotionalProducts &&
      promotionalProducts.length > 0 &&
      store.showProductsWithPromotion &&
      bestSellingProducts &&
      bestSellingProducts?.length > 0 &&
      store?.showBestSellerProducts
    ) &&
      categoriesWithSubcategories) ||
    store.layout === secondLayoutId;

  const showWithoutCategoryProducts =
    withoutCategoryProducts && withoutCategoryProducts.length > 0;

  useEffect(() => {
    if (isOpen?.error) {
      toast({
        description:
          'Ao tentar verificar se a loja está dentro do horário de funcionamento.',
      });
    }
  }, [isOpen?.error, toast]);

  useEffect(() => {
    if (typeof isOpen?.data === 'boolean') {
      setIsOpen(isOpen.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen?.data]);

  useEffect(() => {
    if (bestSellingProductsData) {
      setBestSellingProducts(bestSellingProductsData);
    }

    if (withoutCategoryProductsData) {
      setWithoutCategoryProducts(withoutCategoryProductsData);
    }

    if (promotionalProductsData) {
      setPromotionalProducts(promotionalProductsData);
    }
  }, [
    bestSellingProductsData,
    promotionalProductsData,
    setBestSellingProducts,
    setCategoriesWithSubcategories,
    setPromotionalProducts,
    setWithoutCategoryProducts,
    withoutCategoryProductsData,
  ]);

  const fallback = useMemo(() => isFallback || loading, [isFallback, loading]);
  if (fallback) return <PageLoading text="Carregando os produtos" />;

  return (
    <>
      <div className="maxWidth" style={{ padding: 0, paddingBottom: '50px' }}>
        <SortButton />

        {showPromotionalProducts && (
          <Products
            label="Ofertas especiais"
            products={promotionalProducts}
            productSize={isSmallerThan550px ? 'md' : 'lg'}
          />
        )}

        {showBestSellingProducts && (
          <Products label="Os mais vendidos" products={bestSellingProducts} />
        )}

        {showCategories && (
          <CategoriesList categories={categoriesWithSubcategories} />
        )}

        {showCategoryProducts &&
          categoriesWithSubcategories
            ?.sort((a, b) => a.order - b.order)
            ?.map((category) => {
              return (
                // @ts-ignore
                <Fragment key={category._id}>
                  {((category.products && category.products.length > 0) ||
                    (store.layout === secondLayoutId &&
                      category?.subcategories?.length > 0)) && (
                    <Products
                      label={category.name}
                      products={category.products}
                      subcategories={category.subcategories}
                    />
                  )}

                  {store.layout !== secondLayoutId &&
                    category.subcategories?.map(
                      (subcategory) =>
                        subcategory?.products &&
                        subcategory?.products?.length > 0 && (
                          <Products
                            key={`subcategory-products-${subcategory._id}`}
                            label={subcategory.name}
                            products={subcategory.products}
                          />
                        )
                    )}
                </Fragment>
              );
            })}

        {showWithoutCategoryProducts && (
          <Products label="Produtos" products={withoutCategoryProducts} />
        )}
      </div>

      {isSmallerThan650px && <NavBottomBar />}
    </>
  );
};

export default HomePage;
