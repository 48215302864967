import { IoChevronDown } from 'react-icons/io5';
import {
  FaCheck,
  FaSortAlphaDown,
  FaSortAlphaDownAlt,
  FaSortAmountDown,
  FaSortAmountDownAlt,
} from 'react-icons/fa';

import { Menu, MenuOptionGroup } from '@chakra-ui/react';

import { SortTypeEnum, useSortProducts } from '~/hooks/sortProducts';

import {
  StyledMenuButton,
  StyledMenuList,
  StyledMenuItemOption,
} from './styles';

const SortButton: React.FC = () => {
  const { sortProducts, selectedSort } = useSortProducts();

  return (
    <Menu id="sort-button" closeOnSelect>
      <StyledMenuButton>
        <p>Ordenar</p>
        {selectedSort ? <p>(1)</p> : <IoChevronDown />}
      </StyledMenuButton>

      <StyledMenuList>
        <strong>Ordenar por:</strong>

        <MenuOptionGroup>
          <StyledMenuItemOption
            onClick={() => sortProducts(SortTypeEnum.bySmallerPrice)}
          >
            <div>
              <FaSortAmountDownAlt />
              <span>Menor preço</span>
            </div>

            <div
              className={`check ${
                selectedSort === SortTypeEnum.bySmallerPrice ? 'selected' : ''
              }`}
            >
              <FaCheck />
            </div>
          </StyledMenuItemOption>

          <StyledMenuItemOption
            onClick={() => sortProducts(SortTypeEnum.byBiggerPrice)}
          >
            <div>
              <FaSortAmountDown />
              <span>Maior preço</span>
            </div>

            <div
              className={`check ${
                selectedSort === SortTypeEnum.byBiggerPrice ? 'selected' : ''
              }`}
            >
              <FaCheck />
            </div>
          </StyledMenuItemOption>

          <StyledMenuItemOption
            onClick={() => sortProducts(SortTypeEnum.byLettersAZ)}
          >
            <div>
              <FaSortAlphaDown />
              <span>Ordem alfabética (A-Z)</span>
            </div>

            <div
              className={`check ${
                selectedSort === SortTypeEnum.byLettersAZ ? 'selected' : ''
              }`}
            >
              <FaCheck />
            </div>
          </StyledMenuItemOption>

          <StyledMenuItemOption
            onClick={() => sortProducts(SortTypeEnum.byLettersZA)}
          >
            <div>
              <FaSortAlphaDownAlt />
              <span>Ordem alfabética (Z-A)</span>
            </div>

            <div
              className={`check ${
                selectedSort === SortTypeEnum.byLettersZA ? 'selected' : ''
              }`}
            >
              <FaCheck />
            </div>
          </StyledMenuItemOption>
        </MenuOptionGroup>
      </StyledMenuList>
    </Menu>
  );
};

export default SortButton;
