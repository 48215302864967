import styled from 'styled-components';

export const Container = styled.li`
  margin-right: 25px;
  cursor: pointer;

  transition: transform 0.1s;

  max-width: 150px;

  &:hover {
    transform: scale(1.1);
  }

  > h1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    font-size: 13px;
    margin-top: 5px;
    color: ${({ theme }) => theme.colors.onBackground};
  }
`;
