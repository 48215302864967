import styled, { css } from 'styled-components';

import { StoreThemeEnum } from '~/enums/StoreThemeEnum';

interface IListContainerProps {
  verticalProducts?: boolean;
}

interface IListProps {
  paddingBottom?: number;
  verticalProducts?: boolean;
}

interface IDescriptionProps {
  storeTheme: StoreThemeEnum;
}

export const ListContainer = styled.div<IListContainerProps>`
  margin-bottom: 22px;

  ${({ verticalProducts }) =>
    verticalProducts &&
    css`
      margin: ${({ theme }) => `0 ${theme.spacing}`};
      margin-bottom: ${({ theme }) => theme.spacing};

      @media (max-width: ${({ theme }) => theme.mobileWidth}) {
        margin: ${({ theme }) => `0 ${theme.mobileSpacing}`};
        margin-bottom: ${({ theme }) => theme.mobileSpacing};
      }
    `}
`;

export const List = styled.ul<IListProps>`
  display: flex;
  flex-direction: row;

  overflow: auto;
  margin-left: ${({ theme }) => theme.spacing};
  margin-right: 20px;
  padding-bottom: ${(props) =>
    props.paddingBottom ? `${props.paddingBottom}px` : '18px'};
  padding-top: 18px;

  ${({ verticalProducts }) =>
    verticalProducts &&
    css`
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(400px, auto));
      grid-gap: 25px;

      margin-left: 0;

      overflow: hidden;
      padding: 0;

      @media (max-width: 700px) {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
    `}

  @media (max-width: ${({ theme }) => theme.maxWidth}) {
    margin-right: 0;
  }

  @media (max-width: 500px) {
    margin-left: ${({ theme, verticalProducts }) =>
      !verticalProducts && theme.mobileSpacing};
  }
`;

export const Description = styled.p<IDescriptionProps>`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  font-size: 12px;
  color: ${({ theme }) => theme.colors.onBackground};
  word-break: break-word;
`;
