import { FC } from 'react';

import { useTheme } from 'styled-components';

import { useStore } from '~/hooks/store';
import { useMediaQuery } from '~/hooks/mediaQuery';
import { useLayouts } from '~/hooks/layouts';

import { LabelText } from '~/components/LabelText';
import { Accordion } from '~/components/Accordion';

import { List, ListContainer } from '../../styles';

import { SizeEnum } from '~/enums/SizeEnum';

import { IProduct } from '~/interfaces/IProduct';
import { ICategory } from '~/interfaces/ICategory';

import { Product } from './Product';

interface IProps {
  products: IProduct[];
  productSize?: SizeEnum;
  label: string;
  subcategories?: ICategory[];
}

export const Products: FC<IProps> = ({
  products,
  productSize,
  label,
  subcategories,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.mobileWidth})`);
  const { store } = useStore();
  const { secondLayoutId } = useLayouts();

  return (
    <ListContainer verticalProducts={store.layout === secondLayoutId}>
      {store.layout === secondLayoutId ? (
        <Accordion title={label}>
          <List verticalProducts={store.layout === secondLayoutId}>
            {products &&
              products.map(
                (product) =>
                  product && (
                    <Product
                      key={`${Math.random() * 0.001}-${product._id}`}
                      product={product}
                      size={productSize}
                      fillAllSpace={store.layout === secondLayoutId}
                    />
                  )
              )}
          </List>

          {subcategories?.length > 0 && (
            <div style={{ marginTop: products?.length > 0 ? '20px' : '0px' }}>
              {subcategories.map((subCat) => (
                <Accordion key={subCat._id} title={subCat.name} invertColors>
                  <List verticalProducts={store.layout === secondLayoutId}>
                    {subCat.products &&
                      subCat.products.map(
                        (product) =>
                          product && (
                            <Product
                              key={`${Math.random() * 0.001}-${product._id}`}
                              product={product}
                              size={productSize}
                              fillAllSpace={store.layout === secondLayoutId}
                            />
                          )
                      )}
                  </List>
                </Accordion>
              ))}
            </div>
          )}
        </Accordion>
      ) : (
        <>
          <LabelText
            style={{
              marginLeft: isMobile ? theme.mobileSpacing : theme.spacing,
            }}
          >
            {label}
          </LabelText>

          <List verticalProducts={store.layout === secondLayoutId}>
            {products &&
              products.map(
                (product) =>
                  product && (
                    <Product
                      key={`${Math.random() * 0.001}-${product._id}`}
                      product={product}
                      size={productSize}
                      fillAllSpace={store.layout === secondLayoutId}
                    />
                  )
              )}
          </List>
        </>
      )}
    </ListContainer>
  );
};
