import { FC } from 'react';

import { Container } from './styles';

import { Image } from '../Image';

interface IProps {
  text: string;
}

export const PageLoading: FC<IProps> = ({ text }) => {
  return (
    <Container>
      <footer>
        <div>
          <div className="loader" />
          <p>{text}</p>
        </div>

        <Image
          src="/images/powered-by.png"
          alt="powered-by"
          width={90}
          height={63}
        />
      </footer>
    </Container>
  );
};
