import { IProduct } from '~/interfaces/IProduct';

export function checkIfProductIsCombo(product: IProduct): boolean {
  const { comboJourneys, comboType } = product;

  if (comboJourneys && comboJourneys.length) {
    return true;
  }

  if (comboType) {
    return true;
  }

  return false;
}
