const isPromotionalPrice = (
  price: number,
  promotionalPrice: number
): boolean => {
  if (promotionalPrice && promotionalPrice < price) return true;

  return false;
};

export { isPromotionalPrice };
