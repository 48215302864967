import { FC, useCallback } from 'react';

import { useRouter } from 'next/router';

import useMedia from 'use-media';
import { useTheme } from 'styled-components';

import { routes } from '~/constants/routes';

import { useStore } from '~/hooks/store';
import { useLayouts } from '~/hooks/layouts';
import { useCart } from '~/hooks/cart';

import { Description } from '~/components/styles';
import { ImageContainer } from '~/components/ImageContainer';
import { Button } from '~/components/Button';
import { Badge } from '~/components/Badge';

import { isPromotionalPrice } from '~/utils/isPromotionalPrice';
import { formatCurrency } from '~/utils/formatCurrency';

import { SizeEnum } from '~/enums/SizeEnum';

import { IProduct } from '~/interfaces/IProduct';
import { checkIfProductIsCombo } from '~/utils/checkIfProductIsCombo';
import { getComboMinPrice } from '~/utils/getComboMinPrice';
import { Container, OldPrice } from './styles';

interface IProps {
  product: IProduct;
  size?: SizeEnum;
  fillAllSpace?: boolean;
}

export const Product: FC<IProps> = ({ product, fillAllSpace, size = 'md' }) => {
  const { push } = useRouter();
  const { editingProduct, setEditingProduct } = useCart();
  const { store } = useStore();
  const { secondLayoutId } = useLayouts();
  const theme = useTheme();
  const maxWidthMobile = useMedia({ maxWidth: theme.mobileWidth });

  const handleProductClick = useCallback(() => {
    if (editingProduct) setEditingProduct(null);

    push(routes.product(store?.tag, product._id));
  }, [editingProduct, product._id, push, setEditingProduct, store?.tag]);

  return (
    <Container
      size={size}
      onClick={handleProductClick}
      fillAllSpace={fillAllSpace}
    >
      <ImageContainer
        darkBorder={store.layout === secondLayoutId && !product.pictures[0]}
        pictures={[product.pictures[0]]}
        size={size}
      />

      <div className="content">
        <div>
          <h1>{product.name}</h1>

          {store.layout === secondLayoutId ? (
            <>
              {isPromotionalPrice(product.price, product.promotionalPrice) ? (
                <>
                  <div>
                    <OldPrice>
                      De <b>{formatCurrency(product.price)}</b> Por
                    </OldPrice>

                    <Badge size={maxWidthMobile ? 'sm' : 'md'}>
                      {formatCurrency(
                        isPromotionalPrice(
                          product.price,
                          product.promotionalPrice
                        )
                          ? product.promotionalPrice
                          : product.price
                      )}
                    </Badge>
                  </div>
                </>
              ) : (
                <Badge size={maxWidthMobile ? 'sm' : 'md'}>
                  {checkIfProductIsCombo(product)
                    ? `a partir de ${formatCurrency(getComboMinPrice(product))}`
                    : formatCurrency(product.price)}
                </Badge>
              )}

              {product.description && (
                <Description storeTheme={store.theme}>
                  {product.description}
                </Description>
              )}
            </>
          ) : isPromotionalPrice(product.price, product.promotionalPrice) ? (
            <>
              <div className="promotional-price">
                <OldPrice>
                  De <b>{formatCurrency(product.price)}</b>
                </OldPrice>

                <span className="price">
                  Por <b>{formatCurrency(product.promotionalPrice)}</b>
                </span>
              </div>
            </>
          ) : checkIfProductIsCombo(product) ? (
            <span className="price">{`a partir de ${formatCurrency(
              getComboMinPrice(product)
            )}`}</span>
          ) : (
            <span className="price">{formatCurrency(product.price)}</span>
          )}
        </div>

        {store.layout === secondLayoutId && (
          <Button
            backgroundColor="secondary"
            textColor="onSecondary"
            size="sm"
            fillAllSpace={maxWidthMobile}
          >
            Adicionar
          </Button>
        )}
      </div>
    </Container>
  );
};
