import styled, { css } from 'styled-components';

import { SizeEnum } from '~/enums/SizeEnum';

interface IContainerProps {
  size: SizeEnum;
  fillAllSpace?: boolean;
}

export const Container = styled.li<IContainerProps>`
  margin-right: 25px;

  transition: transform 0.2s;
  cursor: pointer;

  &:hover {
    transform: scale(1.1);
  }

  h1 {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    margin-top: 5px;
    margin-bottom: 1px;
    color: ${({ theme }) => theme.colors.onBackground};
  }

  .promotional-price {
    display: flex;
    flex-direction: column;

    > .price {
      font-size: 12px;
      line-height: 12px;

      b {
        font-size: 14px;
      }
    }
  }

  .price {
    font-weight: 700;
    color: #707070;
  }

  max-width: ${(props) =>
    props.size === 'sm'
      ? '80px'
      : props.size === 'md'
      ? '150px'
      : props.size === 'lg'
      ? '210px'
      : props.size === 'xl'
      ? '320px'
      : ''};

  ${(props) =>
    props.size === 'lg'
      ? css`
          .stars {
            svg {
              width: 16px;
              height: 16px;
            }
          }

          h1 {
            font-size: 14.5px;
          }

          .price {
            font-size: 14px;
          }
        `
      : css`
          .stars {
            svg {
              width: 14px;
              height: 14px;
            }
          }

          h1 {
            font-size: 13px;
          }

          .price {
            font-size: 13px;
          }
        `}

  ${({ fillAllSpace }) =>
    fillAllSpace &&
    css`
      display: flex;

      max-width: 100%;
      margin-right: 0;

      cursor: normal;

      transition: all 0.3s;

      &:hover {
        transform: scale(1);
        opacity: 0.7;
      }

      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        width: 100%;

        margin-left: 20px;

        h1 {
          margin-top: 0;
          margin-bottom: 5px;
        }

        p {
          margin: 5px 0;
        }

        button {
          margin-top: auto;
        }
      }
    `}
`;

export const OldPrice = styled.span`
  font-weight: 500;
  color: #707070;
  font-size: 11px !important;
  margin-right: 5px;

  b {
    text-decoration: line-through;
    font-weight: 500;
  }
`;
