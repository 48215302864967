import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 0;

  width: 100%;
  background: ${({ theme }) => theme.colors.secondBackground};

  border-radius: 5px 5px 0 0;

  box-shadow: 0 -3px 3px rgba(0, 0, 0, 0.1);

  .bottom-nav-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    button {
      position: relative;
      width: 55px;

      padding: 10px 0;

      svg {
        width: 30px;
        height: 30px;

        color: ${({ theme }) => theme.colors.onBackground};
      }

      .line {
        position: absolute;
        bottom: 0;

        height: 6px;
        width: 100%;

        background: ${({ theme }) => theme.colors.secondary};
      }

      .cart-quantity {
        display: flex;
        align-items: center;
        justify-content: center;

        position: absolute;
        top: 7px;
        right: 2px;

        background: ${({ theme }) => theme.colors.secondary};
        width: 20px;
        height: 20px;
        border-radius: 100%;

        font-size: 11.5px;
        font-weight: 700;
        color: ${({ theme }) => theme.colors.onSecondary};
      }
    }
  }
`;
